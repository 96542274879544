<template>
	<a-form :form="form" ref="form" @submit="handleSubmit" :layout="form_data.layout ? form_data.layout : 'horizontal'">
		<!--  -->
		<a-form-item v-for="(item, index) in form_data.list" :key="item.name" :label-col="
                form_data.layout == 'inline'
                    ? {}
                    : { span: !item.title ? 0 : 5 }
            " :wrapper-col="
                form_data.layout == 'inline'
                    ? {}
                    : { span: !item.title ? 24 : 19 }
            " v-show="!item.hidden && !item.close ? true : false" :required="item.required" :style="
			    'margin-bottom:' + (form_data.item_margin_bottom
			        ? form_data.item_margin_bottom
			        : form_data.layout == 'inline'
			        ? '15px;'
			        : '24px;')
				+ (item.type == 'input_group' ? 'display:inline-flex;align-items:center;' : '')
			">
			<template v-if="!item.close">
				<span v-if="item.title" slot="label">
					{{ item.title }}
					<a-tooltip v-if="item.tooltip" :title="item.tooltip">
						<a-icon type="question-circle-o" />
					</a-tooltip>
				</span>

				<a-input @change="handleChange" :style="{
                        minWidth:
                            form_data.layout == 'inline' ? '200px' : '100%',
                    }" @input="handleFocus($event, item, index)" v-if="item.type == 'text'" :disabled="item.disabled" :suffix='item.suffix'
					:name="item.name" :placeholder="item.placeholder || '请输入'"
					v-decorator="[item.name, item.options]" />
					
					<!-- oninput="value=value.replace('.', '',).replace('-', '',)" 作者：happy绍棠 https://www.bilibili.com/read/cv28231043/ 出处：bilibili -->
				
				 <a-input-number  @change="handleChange" :style="{
				        minWidth:
				            form_data.layout == 'inline' ? '200px' : '100%',
				    }" @input="handleFocus($event, item, index)" v-if="item.type == 'integer'" 
					:disabled="item.disabled" :name="item.name" :placeholder="item.placeholder"
					v-decorator="[item.name, item.options]"
				               
				                :precision="0"
				                :parser="(value) => value.replace('%', '')"
				                :min="0"
				              
				            />
				
				<a-input @change="handleChange" :style="{
				        minWidth:
				            form_data.layout == 'inline' ? '200px' : '100%',
				    }" @input="handleFocus($event, item, index)" v-if="item.type == 'number'" type='number'
					:disabled="item.disabled" :name="item.name" :placeholder="item.placeholder"
					v-decorator="[item.name, item.options]" />
				<a-textarea @change="handleChange" :name="item.name" v-if="item.type == 'textarea'"
					:rows="item.rows ? item.rows : 4" :placeholder="item.placeholder"
					v-decorator="[item.name, item.options]" />
				<a-switch @change="handleChange($event, item)" :name="item.name" v-if="item.type == 'switch'"
					v-decorator="[item.name, item.options]"></a-switch>
				<a-date-picker @change="get_date($event, item)" :name="item.name" :mode="item.mode || 'date'"
					v-else-if="item.type == 'date'" v-decorator="[item.name, item.options]" />

				<a-month-picker @change="get_date($event, item)" :name="item.name"
					:defaultValue='item.options.defaultValue'  v-else-if="item.type == 'month'"
					v-decorator="[item.name, item.options]" />

				<template v-else-if="item.type == 'range_date'">
					<a-range-picker v-decorator="[item.name, item.options]" :format="item.format ? item.format : ''"
						:mode="item.range_mode ? item.range_mode : []" @change="range_date_change($event, item)" />
					<a-input type="hidden" :name="item.start.name"
						v-decorator="[item.start.name, item.start.options]" />
					<a-input type="hidden" :name="item.end.name" v-decorator="[item.end.name, item.end.options]" />
				</template>
				<a-cascader allowClear v-else-if="item.type == 'cascader'" :options="item.list" :placeholder="item.placeholder||'请选择'"
					@change="get_date($event, item)" v-decorator="[item.name, item.options]"
					:fieldNames="item.replaceFields" :change-on-select="chose_parent"/>
				<a-select allowClear :style="{
                        minWidth:
                            form_data.layout == 'inline' ? '200px' : '100%',
                    }" v-else-if="item.type == 'select'" :disabled="item.disabled" :filter-option="filterOption" :mode="item.mode" showSearch
					allowClear :placeholder="item.placeholder ||'请选择' " v-decorator="[item.name, item.options]"
					@change="get_select_value($event, item)" :dropdownMatchSelectWidth='false'>
					<a-select-option v-for="vo in item.list" :key="vo.key" :title="vo.value" :value="vo.key">
						{{ vo.value }}</a-select-option>
				</a-select>
				<a-input-group compact v-else-if="item.type == 'input_group'" class="input_group">
					<a-select :disabled="item.disabled" :style="{
							minWidth:'95px'
						}" allowClear v-decorator="[item.name.split(',')[0], item.options1]" :placeholder="item.placeholder ||'请选择' ">
						<a-select-option v-for="vo in item.list" :key="vo.key" :title="vo.value" :value="vo.key">
							{{ vo.value }}</a-select-option>
					</a-select>
					<div v-if="item.input_type == 'range_date'" style="width: 230px">
						<a-range-picker v-decorator="[item.name.split(',')[1], item.options]"
							:format="item.format ? item.format : ''" :mode="item.range_mode ? item.range_mode : []"
							@change="range_date_change($event, item)" />
						<a-input type="hidden" :name="item.start.name"
							v-decorator="[item.start.name, item.start.options]" />
						<a-input type="hidden" :name="item.end.name" v-decorator="[item.end.name, item.end.options]" />
					</div>
					<a-input v-else :disabled="item.disabled" :placeholder="item.input_placeholder || '请输入'"
						v-decorator="[item.name.split(',')[1], item.options2]" style="width: 175px" />
				</a-input-group>

				<a-radio-group :disabled="item.disabled"  @change="handleChange" :name="item.name" v-else-if="item.type == 'radio'"
					v-decorator="[item.name, item.options]">
					<template v-if="item.mode == 'button'">
						<a-radio-button v-for="vo in item.list" :key="vo.key" :value="vo.key">{{ vo.value }}
						</a-radio-button>
					</template>
					<template v-else>
						<a-radio v-for="vo in item.list" :key="vo.key" :value="vo.key">{{ vo.value }}</a-radio>
					</template>
				</a-radio-group>
				<a-checkbox-group v-else-if="item.type == 'checkbox'" @change="handleChange" :name="item.name"
					v-decorator="[item.name, item.options]">
					<a-checkbox v-for="vo in item.list" :key="vo.key" :value="vo.key">{{ vo.value }}</a-checkbox>
				</a-checkbox-group>
				<a-upload v-else-if="item.type == 'upload'" listType="picture" :customRequest="upload" v-decorator="[
                        item.name,
                        {
                            valuePropName: 'fileList',
                            getValueFromEvent: norm_files,
                            ...item.options,
                        },
                    ]">
					<a-button>
						<a-icon type="upload" /> 上传
					</a-button>
				</a-upload>
				<a-tree-select  show-search allow-clear v-else-if="item.type == 'tree-select'" :style="{
                        minWidth:
                            form_data.layout == 'inline' ? '200px' : '100%', 
                    }" :dropdownStyle="{ maxHeight: '400px', overflow: 'auto' }" :treeData="item.treeData"
					:placeholder="item.placeholder||'请选择'" :treeDefaultExpandAll="item.treeDefaultExpandAll"
					:multiple="item.multiple" :disabled="item.disabled" v-decorator="[item.name, item.options]"
					:replaceFields="item.replaceFields" @change="tree_select" :treeNodeFilterProp="'name'" :filterTreeNode="filterTreeNode"></a-tree-select>
				<a-time-picker v-else-if="item.type == 'time-picker'" @change="handleChange" :name="item.name"
					v-decorator="[item.name, item.options]" :format="item.format" />
				<div v-if="item.type == 'time_range'">
					<a-time-picker @change="handleChange" :name="item.start.name"
						v-decorator="[item.start.name, item.start.options]" :format="item.start.format" />~
					<a-time-picker @change="handleChange" :name="item.end.name"
						v-decorator="[item.end.name, item.end.options]" :format="item.end.format" />
				</div>

				<template v-else-if="item.type == 'editor'">
					<quill-editor :options="{ ...editorOption, ...item.editorOption }" :ref="'editor_' + item.name"
						@change="onEditorChange($event, item)" :content="item.content"
						@paste.native.stop="imagePaste($event, item)" class="editor_wrap"></quill-editor>
					<a-input type="hidden" :name="item.name" @change="editor_hidden_change"
						v-decorator="[item.name, item.options]" />
					<!-- <div v-if="item.tips" class="tips">{{ item.tips }}</div> -->
				</template>

				<div v-if="item.type == 'area'">
					<a-cascader allowClear :options="area" :placeholder="item.placeholder || ('请选择' + item.title)"
						@change="get_area($event, item)" v-decorator="[item.name, item.options]" />
				</div>

				<slot v-else-if="item.type == 'slot'" :options="item.list" :name="item.name"
					v-decorator="[item.name, item.options]"></slot>
			</template>
		</a-form-item>
		
		<a-form-item v-show="form_data.show_submit_btn ? true : false"
			:label-col="form_data.layout == 'inline' ? {} : { span: 5 }"
			:wrapper-col="form_data.layout == 'inline' ? {} : { span: 17 }" :style="{
                marginBottom: form_data.item_margin_bottom
                    ? form_data.item_margin_bottom
                    : form_data.layout == 'inline'
                    ? '15px'
                    : '24px',
            }">
			<span v-if="form_data.layout != 'inline'" slot="label">操作</span>
			<a-button class="mr15" type="primary" html-type="submit">{{
                form_data.submit_name ? form_data.submit_name : "提 交"
            }}</a-button>
			<a-button class="mr15" v-show="!form_data.close_reset_btn" @click="handleReset">重 置</a-button>
			<a-button class="mr15" v-show="form_data.show_close_btn" @click="handleClose">取 消</a-button>
		</a-form-item>
	</a-form>
</template>

<script>
	import 'quill/dist/quill.core.css'
	import 'quill/dist/quill.snow.css'
	import 'quill/dist/quill.bubble.css'

	import {
		quillEditor,
		Quill
	} from 'vue-quill-editor'
	// import { container, ImageExtend, QuillWatch } from 'quill-image-extend-module'
	import video from 'quill-video-image-module/video'
	Quill.register(video, true)

	import req from '../methods/req'
	import {
		container,
		VideoExtend,
		ImageExtend,
		QuillWatch,
		QuillVideoWatch
	} from 'quill-video-image-module'
	import {
		getRegion
	} from "@/api/public";
	Quill.register('modules/ImageExtend', ImageExtend)
	Quill.register('modules/VideoExtend', VideoExtend)


	export default {
		props: {
			form_data: {
				type: Object,
				default: () => {
					return {
						list: []
					}
				}
			},
			chose_parent: {
				type: Boolean,
				default: false,
			},
			tips: {
				type: String,
				default: '',
			},
		},
		components: {
			quillEditor
		},
		data() {
			return {
				allowClear:true,
				area: [], // 地区JSON
				editorOption: {
					placeholder: '',
					modules: {
						ImageExtend: {
							action: this.$config.host + "/manage/api.plugs/upload",
							name: 'file',
							start: (res) => {
							},
							response: (res) => {
								return res.data.upload_data.url
							},
							headers: (xhr) => {
								xhr.setRequestHeader('token', this.$method.get_local('token'))
							}
						},
						VideoExtend: {
							action: this.$config.host + "/manage/api.plugs/upload",
							name: 'file',
							start: (res) => {
							},
							response: (res) => {
								let url = res.data.upload_data.url.split('?')[0];
								return url
							},
							headers: (xhr) => {
								xhr.setRequestHeader('token', this.$method.get_local('token'))
							}
						},
						toolbar: {
							container: container,
							handlers: {
								"image": function(value) {
									QuillWatch.emit(this.quill.id)
									// let url = self.imgURL.split('?')[0];
									// self.quill.insertEmbed(QuillWatch.active.cursorIndex, 'video', url)
								},
								"video": function(value) {
									QuillVideoWatch.emit(this.quill.id)
									let _this = this;
									// _this.quill.insertText(1, '<span>1</span>')
									// _this.quill.clipboard.dangerouslyPasteHTML(1, '<span>22<span><video src="https://crm-img.runtian.cn/crm-dev.waakuu.com/manage/20210422/63a4c610a61b0b90/b0621c9cacef7d09.mp4"></video>')

									// let fileInput = document.querySelector('.quill-video-input');
									// if (fileInput === null) {
									//     fileInput = document.createElement('input');
									//     fileInput.setAttribute('type', 'file');
									//     fileInput.classList.add('quill-video-input');
									//     fileInput.style.display = 'none'
									//     // 监听选择文件
									//     fileInput.addEventListener('change', () => {
									//         let file = fileInput.files[0]
									//         req({
									//             mode: 'file',
									//             url: '/manage/api.plugs/upload',
									//             loading: true,
									//             loading_msg: '上传中...',
									//             data: {
									//                 file: file,
									//             }
									//         }).then(res => {
									//             let url = res.data.upload_data.url.split('?')[0];
									//             _this.quill.insertEmbed(url, 'video', url)

									//         }).catch(e => {

									//         })
									//     })
									//     document.body.appendChild(fileInput);
									// }
									// fileInput.click();

									// QuillWatch.emit(this.quill.id)
								}
							}
						}
					}
				}
			}
		},

		beforeCreate() {
			this.form = this.$form.createForm(this, {
				name: 'seo'
			});


		},
		created() {
			// 如果表单中有area类型则获取地区数据
			if (this.form_data.list.findIndex(item => {
					return item.type == 'area'
				}) > -1) {
				this.get_area_list();
			}
		},
		methods: {
			
			 filterOption(input, option) {
			      return (
			        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
			      );
			    },
			get_area_list() {
				getRegion().then(res => {
					this.area = res.data.list;
				})
			},
			get_area(e, item) {
			},
			tree_select(e, label, extra) {
				this.$emit('tree_select', label)
			},
			get_select_value(e,item) {
				this.$emit('get_select_value_tr', {
					label:item.name,
					value:e
				})
				this.$emit('get_select_value', e)
			},
			get_date(e, item) {
				this.$emit('get_date', e)
				if (item.type == 'month') {
					this.form.setFieldsValue({
						[item.name]: this.$method.get_date(e, 'YYYY-MM')
					})
				}

			},
			range_date_change(e, item) {
				let data = {};
				data[item.start.name] = this.$method.get_date(e[0]);
				data[item.end.name] = this.$method.get_date(e[1]);
				this.form.setFieldsValue(data)

			},
			handleSubmit(e) {
				e.preventDefault();
				this.submit();
			},
			handleReset() {
				this.form.resetFields();
				
				this.$emit('handleReset', {});
				this.$forceUpdate()
			},
			handleClose() {
				this.$router.back();
			},
			handleChange(e, label) {
				this.$emit('handleChange', {
					e,
					label
				})
			},
			handleFocus(e, item, index) {
				this.$emit('handleFocus', {
					e,
					item,
					index
				})
			},
			setFieldsValue(data) {
				this.form.setFieldsValue(data)
			},
			editor_hidden_change(e) {
				
			},
			onEditorChange({
				html
			}, item) {
				let data = {};
				data[item.name] = html;
				this.form.setFieldsValue(data)
			},
			imagePaste(e, item) {
				if (e.clipboardData && e.clipboardData.items) {
					
					let items = e.clipboardData.items;
					for (let i = 0; i < items.length; i++) {
						if (items[i] && items[i].kind === 'file') {
							e.preventDefault();
							if (items[i].type.match(/^image\//i)) {
								this.$req({
									mode: 'file',
									url: '/manage/api.plugs/upload',
									data: {
										file: items[i].getAsFile(),
									}
								}).then(res => {
									let quill = this.$refs["editor_" + item.name][0].quill;
									let length = quill.getSelection().index;
									quill.insertEmbed(length, "image", res.data.upload_data.url);
									quill.setSelection(length + 1);
								}).catch(e => {
									
								})
							}
						}
					}
				}
			},
			upload(e, item) {
				return new Promise((resolve, reject) => {
					this.$req({
						mode: 'file',
						url: '/manage/api.plugs/upload',
						data: {
							file: e.file,
						}
					}).then(res => {
						e.onSuccess(res.data.upload_data);
						resolve(res);
					}).catch(e => {
						e.onError();
						reject(e)
					})
				})
			},
			norm_files(e) {
				e.fileList = e.fileList.slice(-1);
				return e && e.fileList
			},

			submit(need_submit = true) {
				return new Promise((resolve, reject) => {
					this.form.validateFields((err, values) => {
						if (!err) {

							values = {
								...values
							};

							let file = [];
							this.form_data.list.forEach(item => {
								if (item.type == 'upload') {
									file.push(item.name)
								} else if (item.type == 'month') {
									values[item.name] = this.$method.get_date(values[item.name],
										'YYYY-MM')
								} else if (item.type == 'date') {
									values[item.name] = this.$method.get_date(values[item.name],
										'YYYY-MM-DD')
								}
								else if (item.type == 'area') {
									values[item.name] = values[item.name].toString();
								}
							});
							for (let key in values) {
								if (file.indexOf(key) >= 0) {
									values[key] = this.$method.files_to_string(values[key]);
								}
							}

							if (values['range_date']) {
								delete values['range_date']
							}
							if (need_submit) {
								this.$emit('handleSubmit', {
									values,
								})

							}

							resolve(values)
						}
					});
				})
			},
            filterTreeNode(searchVal,treeNode){
                return treeNode.data.props.title.includes(searchVal)
            }
		}
	}
</script>

<style lang="less" scoped>
	@import "../assets/less/editor.less";

	.input_group {
		display: flex !important;
	}
	.tips {
		line-height: 20px;
		font-size: 12px;
		color: #ababab;
		white-space: pre-line;
	}
	/deep/.ant-cascader-menu{
	  height: 400px !important;
	}
	
</style>
